<template>
  <div>
    <ManagerNavbar />
  </div>
  <div class="what-sales-together">
    <h1>Items Sold Together</h1>

    <p>Input Date Range</p>

    <div class="row justify-content-center">
      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormStartDate"></label>
        <input
          type="text"
          v-model="start_date"
          class="form-control"
          id="inlineFormStartDate"
          placeholder="Start Date: mm/dd/yy"
          ref="s_input"
        />
      </div>

      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormEndDate"></label>
        <input
          type="text"
          v-model="end_date"
          class="form-control"
          id="inlineFormEndDate"
          placeholder="End Date: mm/dd/yy"
          ref="e_input"
        />
      </div>

      <div class="col-auto my-1">
        <button type="submit" @click="generateReport()" class="btn btn-primary">
          Generate Report
        </button>
      </div>
    </div>

    <table class="table table-hover table-bordered mt-5">
      <thead>
        <tr>
          <th scope="col">Items</th>
          <th scope="col">Number of Times Sold Together</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(count, index) in counts" :key="index">
          <td>{{ item_pairs[index] }}</td>
          <td>{{ count }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ManagerNavbar from "../../../components/ManagerNavbar.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

export default {
  name: "WhatSalesTogether",
  components: {
    ManagerNavbar,
  },
  data() {
    return {
      counts: [],
      item_pairs: [
        "Original Cheese Pizza and Pepperoni Pizza",
        "Original Cheese Pizza and 1 Topping Pizza",
        "Original Cheese Pizza and 2-4 Topping Pizza",
        "Original Cheese Pizza and Fountain Drink",
        "Pepperoni Pizza and 1 Topping Pizza",
        "Pepperoni Pizza and 2-4 Topping Pizza",
        "Pepperoni Pizza and Fountain Drink",
        "1 Topping Pizza and 2-4 Topping Pizza",
        "1 Topping Pizza and Fountain Drink",
        '2-4 Topping Pizza and Fountain Drink"',
      ],
      start_date: "",
      end_date: "",
    };
  },

  methods: {
    // @vuese
    // Sends a query to the database to retrieve orders from the given date range, finds how often each item pair was sold together, then sorts the pairs from most to least often sold together
    generateReport() {
      //get date range from data input fields
      this.start_date = this.$refs.s_input.value;
      this.end_date = this.$refs.e_input.value;
      //get counts
      axios
        .get(
          "https://csce315-project3-backend.herokuapp.com/manager/reports/what-sales-together?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date
        )
        .then((response) => {
          this.counts = response.data;
          //sort counts from least to greatest
          for (var i = 0; i < this.counts.length; i++) {
            for (var j = 0; j < this.counts.length - i - 1; j++) {
              if (this.counts[j] > this.counts[j + 1]) {
                var temp = this.counts[j];
                this.counts[j] = this.counts[j + 1];
                this.counts[j + 1] = temp;

                //when swapping values of counts, also swap values of item_pairs to the indexes of corresponding
                //counts and pairs stay matched up
                var temp2 = this.item_pairs[j];
                this.item_pairs[j] = this.item_pairs[j + 1];
                this.item_pairs[j + 1] = temp2;
              }
            }
          }
          //reverse the order of the arrays so they are greatest to least
          this.counts.reverse();
          this.item_pairs.reverse();
        });
    },
  },
};
</script>
