<template>
    <div class="card" style="width: 25rem;">
        <div class="card-body">
            <p class="card-text">Order Ticket</p>
            
        </div>
        <button class="button">
            <div>
                <v-btn pill>Checkout</v-btn>
            </div>
        </button>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
    .card{
        margin: 0.5em 0.5em;
        padding: 0px;
        height: 50rem;
    }
    .button{
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(9, 206, 220);
      border-radius: 3px;
      color: rgb(9, 206, 220);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .button:hover {
      background: rgb(9, 206, 220);
      color: rgb(255, 255, 255);
    }

    p{
        font-size: 20px;
        font-weight: bold;
    }
</style>