
<template>
  <div><ManagerNavbar/></div>
  <div class="restock-report">
    <h1>Restock Report</h1>

      <div class="col-auto my-1">
        <button type="submit" @click="generateReport()" class="btn btn-primary">
          Generate Report
        </button>
      </div>

      <div class="col-auto my-1">
        <button type="submit" @click="restock()" class="btn btn-primary">
          Restock
        </button>
      </div>


    <table class="table table-hover table-bordered mt-5">
      <thead>
        <tr>
          <th scope="col">Items to Restock</th>
          <th scope="col">Number of Servings</th>
        </tr>
      </thead>
      <tbody>
      
        <tr v-for="item in understocked_items" :key="item.item_name">
          <td>{{ item.item_name }}</td>
          <td>{{ item.num_servings }}</td>
        </tr>

      </tbody>
    </table>
  </div>
</template>



<script>
import ManagerNavbar from '../../../components/ManagerNavbar.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

export default {
  name: 'restockReport',
  components: {
    ManagerNavbar
  },
  data(){return{
    understocked_items: []
  }},
  methods: {
    //@vuese
    //sends a query to the database to retrieve the inventory items that are understocked
    generateReport(){
      axios
        .get("https://csce315-project3-backend.herokuapp.com/manager/reports/RestockReport")
        .then((response) => {
          this.understocked_items = response.data
        })
        .catch(err =>{
          console.log(err)
        })
    },
    //@vuese
    //Sends a query to the database to restock inventory items that are understocked
    restock(){
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/reports/RestockReport/restock")
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    }
  }
}
</script>
