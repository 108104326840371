<template>

  <div class="container" style="margin-top: 50px;">
    <div class="child">
      <div class="card" style="width: 25rem;">
        <div class="card-body">
            <h5 class="card-title">Order Ticket</h5>
            <p class="card-text" v-for ="item in orderItems" :key="item">{{item}}</p>
        </div>

        <div id="bottom">
          <p> Subtotal: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${{orderTotal}}</p>
          <p>Estimated Tax: ${{Math.round((orderTotal * .0625) * 100) / 100}}</p>
          <p>Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${{orderTotalWithTax}}</p>
        </div>


        <button class="checkout">
            <div>
                <v-btn @click="submitOrder" pill>Checkout</v-btn>
            </div>
        </button>
      </div>
    </div>
    <div class="child">
        <p style="text-align: center;">Meats:</p>
        <div class="meats">
          <button type="button" class="diced-ham" data-toggle="button" @click="addDicedHam">
            Diced Ham
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="italian-sausage">
            <v-btn @click="onClick" :onClick="addItalianSausage" >Italian Sausage</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="meatball">
            <v-btn @click="onClick" :onClick="addMeatball" >Meatball</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="pepperoni">
            <v-btn @click="onClick" :onClick="addPepperoni" >Pepperoni</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="salami">
            <v-btn @click="onClick" :onClick="addSalami" >Salami</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="smoked-chicken">
            <v-btn @click="onClick" :onClick="addSmokedChicken" >Smoked Chicken</v-btn>
          </button>
        </div>
        <br />
        <p style="text-align: center;">Veggies:</p>
        <div class="veggies">
          <button class="banana-peppers">
            <v-btn @click="onClick" :onClick="addBananaPeppers" >Banana Peppers</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="black-olives">
            <v-btn @click="onClick" :onClick="addBlackOlives" >Black Olives</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="green-peppers">
            <v-btn @click="onClick" :onClick="addGreenPeppers" >Green Peppers</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="jalapenos">
            <v-btn @click="onClick" :onClick="addJalapenos" >Jalapenos</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="mushrooms">
            <v-btn @click="onClick" :onClick="addMushrooms" >Mushrooms</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="onions">
            <v-btn @click="onClick" :onClick="addOnions" >Onions</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="pineapple">
            <v-btn @click="onClick" :onClick="addPineapple" >Pineapple</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="roasted-garlic">
            <v-btn @click="onClick" :onClick="addRoastedGarlic" >Roasted Garlic</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="spinach">
            <v-btn @click="onClick" :onClick="addSpinich" >Spinach</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="tomatoes">
            <v-btn @click="onClick" :onClick="addTomatoes" >Tomatoes</v-btn>
          </button>
        </div>
        <br />
        <p style="text-align: center;">Add On:</p>
        <div class="add-on">
          <button class="add-cauli">
            <v-btn @click="onClick" :onClick="addCauliflowerCrust" >Cauliflower Crust</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="drink">
            <v-btn @click="onClick" :onClick="addDrink" >Drink</v-btn>
          </button>
          &nbsp; &nbsp; &nbsp;
          <button class="seasonal">
            <v-btn @click="onClick" :onClick="addSeasonal">Seasonal</v-btn>
          </button>
        </div>
        <br />
        <button class="add-item">
          <v-btn @click="onClick" :onClick="generateOrderView" >Add Item</v-btn>
        </button>
        &nbsp; &nbsp; &nbsp;
        <button class="restart-item">
          <v-btn @click="onClick" :onClick="removeStates" >Restart Item</v-btn>
        </button>
        </div>
    </div>
</template>

<script>
    import Order_ticket from '../../components/Order_ticket.vue'
    import Btn_seasonal from '../../components/Btn_seasonal.vue'
    import Btn_additem from '../../components/Btn_additem.vue'
    import "bootstrap/dist/css/bootstrap.min.css";
    import axios from "axios";

  export default {
  name: 'CashierView',
  components: {
    Order_ticket, Btn_seasonal, Btn_additem
    
  },
  data() {
    return {
      toppings: [],
      orderItems: [],
      itemQueries: [],
      addCauliflower: false,
      addCauliQuery: 'FALSE',
      numItems: 0,
      numToppings: 0,
      isDrink: false,
      itemName: "",
      maxOrderItemID: 0,
      maxOrderNumID: 0,
      itemPrice: 0,
      orderTotal: 0,
      orderIDCounter: 0,
      orderTotalWithTax:0,
      seasonal_name: "",
      isSeasonal: false,
    }
  },
  mounted() {

        axios
            .post("https://csce315-project3-backend.herokuapp.com/cashier/buttons/getName")
            .then((response) => {
                console.log(response)
                this.seasonal_name = response.data

            })
            .catch((error) => console.log(error));

  },

  methods: {
    //@vuese
    //adds selected topping to toppings array
    addDicedHam() {
      
      this.toppings.push('Diced Ham')
      this.numToppings++
      $(".diced-ham").addClass("active-red");
      
    },
    //@vuese
    //adds selected topping to toppings array
    addItalianSausage() {
      this.toppings.push('Italian Sausage')
      this.numToppings++
      
      $(".italian-sausage").addClass("active-red");
    },
    //@vuese
    //adds selected topping to toppings array
    addMeatball() {
      this.toppings.push('Meatball')
      this.numToppings++
      $(".meatball").addClass("active-red");

    },
    //@vuese
    //adds selected topping to toppings array
    addPepperoni() {
      this.toppings.push('Pepperoni')
      this.numToppings++
      $(".pepperoni").addClass("active-red");
    },
    //@vuese
    //adds selected topping to toppings array
    addSalami() {
      this.toppings.push('Salami')
      this.numToppings++
      $(".salami").addClass("active-red");
    },
    //@vuese
    //adds selected topping to toppings array
    addSmokedChicken() {
      this.toppings.push('Smoked Chicken')
      this.numToppings++
      $(".smoked-chicken").addClass("active-red");
    
    },
    //@vuese
    //adds selected topping to toppings array
    addBananaPeppers() {
      this.toppings.push('Banana Peppers')
      this.numToppings++
      $(".banana-peppers").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addBlackOlives() {
      this.toppings.push('Black Olives')
      this.numToppings++
      $(".black-olives").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addGreenPeppers() {
      this.toppings.push('Green Peppers')
      this.numToppings++
      $(".green-peppers").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addJalapenos() {
      this.toppings.push('Jalapenos')
      this.numToppings++
      $(".jalapenos").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addMushrooms() {
      this.toppings.push('Muchrooms')
      this.numToppings++
      $(".mushrooms").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addOnions() {
      this.toppings.push('Onions')
      this.numToppings++
      $(".onions").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addPineapple() {
      this.toppings.push('Pineapple')
      this.numToppings++
      $(".pineapple").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addRoastedGarlic() {
      this.toppings.push('Roasted Garlic')
      this.numToppings++
      $(".roasted-garlic").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addSpinich() {
      this.toppings.push('Spinach')
      this.numToppings++
      $(".spinach").addClass("active-green");
    },
    //@vuese
    //adds selected topping to toppings array
    addTomatoes() {
      this.toppings.push('Tomatoes')
      this.numToppings++
      $(".tomatoes").addClass("active-green");
    },
    //@vuese
    // changes cauliflower variable to true
    addCauliflowerCrust() {
      this.addCauliflower = true
      this.addCauliQuery = 'TRUE'
      $(".add-cauli").addClass("active-purple");

    },
    //@vuese
    //changes drink to true
    addDrink() {
      this.isDrink = true;
      $(".drink").addClass("active-purple");
      
    },
    //@vuese
    //changes seasonal variable to true
    addSeasonal() {
      this.isSeasonal = true;
      $(".seasonal").addClass("active-purple");
    },
    //@vuese
    //removes all states from buttons & resets variables
    removeStates() {
      this.isSeasonal = false;
      this.isDrink = false;
      this.addCauliflower = false;
      this.addCauliQuery = "FALSE";
      this.toppings.splice(0);
      this.numToppings = 0;
      this.itemName = "";
      this.itemPrice = 0;
      this.resetbtnstate();
    },
    //@vuese
    //resets button states
    resetbtnstate() {
      $("button").removeClass("active-red");
      $("button").removeClass("active-green");
      $("button").removeClass("active-purple");
    },



    //@vuese
    //generates the order view for website
    async generateOrderView() {

      // gets most recent order num & order item num
      await this.getMaxOrderItemID()
      await this.getMaxOrderNumID()


      //IF SEASONAL ITEM
      if(this.isSeasonal) {
        this.itemName = this.seasonal_name
        await this.getItemPrice(this.seasonal_name)
        this.orderItems[this.numItems] = this.seasonal_name + " --------------------------- " + this.itemPrice 
      }

      // IF DRINK
      else if (this.isDrink) {
        
        this.itemName = "Fountain Drink"
        await this.getItemPrice("Fountain Drink")
        this.orderItems[this.numItems] = "Drink ------------------------------------- " + this.itemPrice
        

      }
      else {
        // IF CHEESE PIZZA
        if (this.numToppings == 0) {

          this.itemName = "Original Cheese Pizza"
          await this.getItemPrice("Original Cheese Pizza")

          if (this.addCauliflower) {

            this.itemPrice += 2.99
            this.itemPrice = Math.round(this.itemPrice * 100) / 100
            this.orderItems[this.numItems] = "Cheese Pizza w/ Cauliflower --------- " + this.itemPrice

          } else {

            this.orderItems[this.numItems] = "Cheese Pizza ----------------------------- " + this.itemPrice

          }
          


        }
        // IF PEPPERONI PIZZA
        else if (this.numToppings == 1 && this.toppings[0] == 'Pepperoni') {
          
          this.itemName = "Pepperoni Pizza"
          await this.getItemPrice("Pepperoni Pizza")

          if (this.addCauliflower) {

            this.itemPrice += 2.99
            this.itemPrice = Math.round(this.itemPrice * 100) / 100
            this.orderItems[this.numItems] = "Pepperoni Pizza w/ Cauliflower ----- "+ this.itemPrice

          } else {

            this.orderItems[this.numItems] = "Pepperoni Pizza ------------------------- " + this.itemPrice

          }
          


          // IF ONE TOPPING PIZZA
        } else if (this.numToppings == 1) {
          
          this.itemName = "1 Topping Pizza"
          await this.getItemPrice("1 Topping Pizza")

          // determines output
          if (this.addCauliflower) {

            this.itemPrice += 2.99
            this.itemPrice = Math.round(this.itemPrice * 100) / 100

            this.orderItems[this.numItems] = "1 Topping Pizza w/ Cauliflower ---- "+ this.itemPrice

          }
          else {

            this.orderItems[this.numItems] = "1 Topping Pizza  ------------------------- "+ this.itemPrice
          }
          // add ingredients to array
          
          this.orderItems[this.numItems] += " " + this.toppings[0]

          // ELSE, 2 TOPPING PIZZA
        } else {
          
          this.itemName = "2-4 Topping Pizza"
          await this.getItemPrice("2-4 Topping Pizza")
          if (this.addCauliflower) {

            this.itemPrice += 2.99
            this.itemPrice = Math.round(this.itemPrice * 100) / 100
            this.orderItems[this.numItems] = "2-4 Topping Pizza w/ Cauliflower -- " + this.itemPrice

          } else {

            this.orderItems[this.numItems] = "2-4 Topping Pizza ----------------------- " + this.itemPrice

          }
          

          // appending toppings to order window
          for (var i = 0; i < this.numToppings; i++) {
            if (i + 1 == this.numToppings) {
              this.orderItems[this.numItems] += " " + this.toppings[i]
              continue
            }
            this.orderItems[this.numItems] += " " + this.toppings[i] + ", "

          }
          

        }

      }

        this.orderIDCounter++
        this.orderTotal += this.itemPrice // price to item total
        this.orderTotal = Math.round(this.orderTotal * 100) / 100

        this.orderTotalWithTax = this.orderTotal * 1.0625 // appends to order total with tax
        this.orderTotalWithTax = Math.round(this.orderTotalWithTax * 100) / 100
        await this.populateQueries()

        this.numItems++
        this.isSeasonal = false
        this.isDrink = false
        this.addCauliflower = false
        this.addCauliQuery = 'FALSE'
        this.toppings.splice(0)
        this.numToppings = 0
        this.resetbtnstate()



    

    },

    //@vuese 
    //submits a post request that returns the max item number
    async getMaxOrderItemID() {

      await axios.post("https://csce315-project3-backend.herokuapp.com/cashier/order-item").then ((resp) => {
        console.log(resp)
        this.maxOrderItemID = resp.data.max
      })

    },


    //@vuese
    //submits a post request that returns the max order number
    async getMaxOrderNumID() {
      await axios.post("https://csce315-project3-backend.herokuapp.com/cashier/order-num").then ((resp) => {
        console.log(resp)
        this.maxOrderNumID = resp.data.max
      })
    },

    //@vuese
    //submits a post request that returns the specified item price
    async getItemPrice(item_name) {
      await axios.post("https://csce315-project3-backend.herokuapp.com/cashier/item-price", {itemName: item_name})        
      .then((response) => {console.log(response)
          this.itemPrice = response.data.item_price})
        .catch((error) => console.log(error));
    },

    // @vuese
    //submits order and posts to psql database
    async populateQueries() {
      this.itemQueries[this.numItems] = "INSERT INTO order_items(order_item_id, order_item_name, price_at_time_of_purchase, "
        + "ingredient1_name, ingredient2_name, ingredient3_name, ingredient4_name, use_cauliflower_crust, order_num) VALUES("

      this.itemQueries[this.numItems] += (this.maxOrderItemID + this.orderIDCounter) + ",\'" + this.itemName + "\', "+ this.itemPrice + ", ";

      for (var i = 0; i < this.numToppings; i++) {        
          this.itemQueries[this.numItems] += "\'" + this.toppings[i] + "\', "; // appends ingredient to query
      }

      while(this.numToppings < 4) {
          this.itemQueries[this.numItems] += "\'NULL\', ";
          this.numToppings++;
      }

      this.itemQueries[this.numItems] += "\'" + this.addCauliQuery + "\', " + (this.maxOrderNumID +1)  + ");";

    },
 
    //@vuese
    //submits item list queries & order query and calls submit query to database, then clears all variables
    async submitOrder() {

      // gets current date  
      const current = new Date();
      const date = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear().toString().substr(-2)}`


      // rounds order total to 2 decimal places
      this.orderTotal *= 1.0625
      this.orderTotal = Math.round(this.orderTotal * 100) / 100
      var employee_id = 1

      const order_query = "INSERT INTO orders(order_num, date, order_price, customer_id, employee_id)"
            + "VALUES (" + (this.maxOrderNumID + 1)  + ",\'" + date + "\'," + (this.orderTotal)
            + ", " + (this.maxOrderNumID + 1) + ", " + employee_id + ");"; 

      
      // submits all the item queries
      for(var i = 0; i < this.numItems; i++) {
          await this.submitQuery(this.itemQueries[i])
        }

      //submits the order query
      await this.submitQuery(order_query)
      
      // reset values
      alert(`Submitted order, total was ${this.orderTotal}`)
      this.orderTotal = 0
      this.orderIDCounter = 0
      this.orderTotalWithTax = 0
      this.itemQueries.splice(0)
      this.orderItems.splice(0)
      await this.reloadPage()








    },


    //@vuese
    //executes passed query
    async submitQuery(currQuery) {
      await axios
        .post("https://csce315-project3-backend.herokuapp.com/cashier/submit-query", {query: currQuery})
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    },
    async reloadPage() {
      window.location.reload();
    },

  }

}
</script>

<style scoped>
    .container{
      display: flex;
    }
    .child{
      flex: 1;
    }

    .card{
        margin: 0.5em 0.5em;
        padding: 0px;
        height: 40rem;
    }
    .checkout{
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(9, 206, 220);
      border-radius: 3px;
      color: rgb(9, 206, 220);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .checkout:hover {
      background: rgb(9, 206, 220);
      color: rgb(255, 255, 255);
    }

    #bottom {
      position: absolute;
      bottom: 4.5rem;
      left: 7rem;
      font-size: 1.2em;
      line-height: 50%;
    }

    p{
        font-size: 20px;
        text-align: left;
    }

    .diced-ham {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(255, 51, 0);
      border-radius: 3px;
      color: rgb(255, 51, 0);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .diced-ham:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);
    }
    .italian-sausage {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(255, 51, 0);
      border-radius: 3px;
      color: rgb(255, 51, 0);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .italian-sausage:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);
    }
    .meatball {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(255, 51, 0);
      border-radius: 3px;
      color: rgb(255, 51, 0);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .meatball:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);
    }
    .pepperoni {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(255, 51, 0);
      border-radius: 3px;
      color: rgb(255, 51, 0);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .pepperoni:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);
    }
    .smoked-chicken {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(255, 51, 0);
      border-radius: 3px;
      color: rgb(255, 51, 0);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .smoked-chicken:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);
    }
    .salami {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(255, 51, 0);
      border-radius: 3px;
      color: rgb(255, 51, 0);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .salami:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);
    }
    .banana-peppers {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .banana-peppers:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .black-olives {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
   .black-olives:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }

    .banana-peppers:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .green-peppers {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .green-peppers:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .jalapenos {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .jalapenos:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .mushrooms {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .mushrooms:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .onions {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .onions:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .pineapple {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .pineapple:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .roasted-garlic {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .roasted-garlic:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .spinach {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .spinach:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .tomatoes {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(8, 162, 36);
      border-radius: 3px;
      color: rgb(8, 162, 36);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .tomatoes:hover {
      background: rgb(8, 162, 36);
      color: rgb(255, 255, 255);
    }
    .add-cauli {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(172, 28, 255);
      border-radius: 3px;
      color: rgb(172, 28, 255);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .add-cauli:hover {
      background: rgb(172, 28, 255);
      color: rgb(255, 255, 255);
    }
    .drink {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(172, 28, 255);
      border-radius: 3px;
      color: rgb(172, 28, 255);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .drink:hover {
      background: rgb(172, 28, 255);
      color: rgb(255, 255, 255);
    }
    .seasonal {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(172, 28, 255);
      border-radius: 3px;
      color: rgb(172, 28, 255);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .seasonal:hover {
      background: rgb(172, 28, 255);
      color: rgb(255, 255, 255);
    }
    .add-item {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(9, 206, 220);
      border-radius: 3px;
      color: rgb(9, 206, 220);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .add-item:hover {
      background: rgb(9, 206, 220);
      color: rgb(255, 255, 255);
    }

    .active-red{
      background: rgb(255, 51, 0);
      color: white;
    }
    .active-green{
      background: rgb(8, 162, 36);
      color: white;


    }
    .active-purple{
      background: rgb(172, 28, 255);
      color: white;


    }

    .restart-item {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(255, 51, 0);
      border-radius: 3px;
      color: rgb(255, 51, 0);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
    .restart-item:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);      
    }

</style>
