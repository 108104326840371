<template>
  <div><ManagerNavbar/></div>
  <div class="sales-report">
    <h1>Sales Report</h1>

    <p>Enter your dates of choice</p>
    <div class="row justify-content-center">
      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormStartDate"></label>
        <input 
          v-model="startDate" 
          class="form-control"
          id="inlineFormStartDate"
          ref="sDate" 
          placeholder="start: mm/dd/yy" 
        />
      </div>
      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormEndDate"></label>
        <input 
          v-model="endDate" 
          class="form-control"
          id="inlineFormEndDate"
          ref="eDate" 
          placeholder="end: mm/dd/yy" 
        />
      </div>
      <div class="col-auto my-1">
        <button type="submit" @click="genSalesReport" class="btn btn-primary">
          Generate Report
        </button>
      </div>
      <div class="col-md-6 align-center">
        <table class="table table-hover table-bordered mt-5">
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Count</th>
            <th scope="col">Revenue</th>
          </tr>
          <tr v-for="(items,count) in 9" :key="items">
            <th>{{menuItem[count]}}</th>
            <th>{{amountSold[count]}}</th>
            <th>{{ amountSold[count + 9] }}</th>
          </tr>
        </table>
      </div>
    </div>
  </div>


</template>



<script>
// @ is an alias to /src
import ManagerNavbar from "../../../components/ManagerNavbar.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

export default {
  name: 'SalesReport',
  components: {
    ManagerNavbar,
  },
  data() {
    return {
      amountSold: [],
      totalCost: [],
      menuItem: [
        "Drink",
        "1 Topping Pizza w/ Cauli Crust",
        "2-4 Topping Pizza w/ Cauli Crust",
        "Cheese Pizza w/ Cauli Crust",
        "Pepperoni Pizza w/ Cauli Crust",
        "1 Topping Pizza",
        "2-4 Topping Pizza",
        "Cheese Pizza",
        "Pepperoni Pizza",
      ],
      startDate: "",
      endDate: "",
    };
  },

  mounted() {
    console.log("MOUNTED");
  },

  methods: {
    //@vuese
    // Sends a query to database to get count of each item and the amount made from each one on a certain date
    genSalesReport(){
      this.startDate = this.$refs.sDate.value;
      this.endDate = this.$refs.eDate.value;
      axios.get('https://csce315-project3-backend.herokuapp.com/manager/reports/sales?startDate='+this.startDate + '&endDate='+this.endDate)
      .then((res)=>{
        this.amountSold = res.data;
      })
      .catch((error)=>{
        console.log("error");
      })
    }
  },
};
</script>
