<template>
  <div>
    <ManagerNavbar />
  </div>

  <div class="container p-5">
    <h1 class="text-center border-bottom pb-3 mb-3">Inventory</h1>
    <!-- These lines create the text fields for user input -->
    <p>Add/update inventory item:</p>
    <div class="row justify-content-center">
      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormInputName">Item name</label>
        <input
          type="text"
          v-model="formData.itemName"
          class="form-control"
          id="inlineFormInputName"
          placeholder="Item name"
        />
      </div>

      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormInputName"
          >Number of Servings</label
        >
        <input
          type="text"
          v-model="formData.numServings"
          class="form-control"
          id="inlineFormInputServings"
          placeholder="Number of Servings"
        />
      </div>
      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormInputName">Item Price</label>
        <input
          type="text"
          v-model="formData.itemPrice"
          class="form-control"
          id="inlineFormInputPrice"
          placeholder="Item Price"
        />
      </div>
      <div class="col-auto my-1">
        <button
          type="submit"
          @click="
            addInventory();
          "
          class="btn btn-primary"
        >
          Add
        </button>
      </div>
      <div class="col-auto my-1">
        <button
          type="submit"
          @click="
            updateInventory();
          "
          class="btn btn-primary"
        >
          Update
        </button>
      </div>
      <div class="col-auto my-1">
        <button
          type="submit"
          @click="
            deleteInventoryItem();
          "
          class="btn btn-primary"
        >
          Delete
        </button>
      </div>
    </div>

    <table class="table table-hover table-bordered mt-5">
      <thead>
        <tr>
          <th scope="col">Item Name</th>
          <th scope="col">Number of Servings</th>
          <th scope="col">Item Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in inventory" :key="item.item_name">
          <td>{{ item.item_name }}</td>
          <td>{{ item.num_servings }}</td>
          <td>{{ item.item_price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ManagerNavbar from "../../components/ManagerNavbar.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import {ref} from 'vue'

export default {
  name: "Inventory",
  components: {
    ManagerNavbar,
  },
  data() {
    return {
      inventory: ref([]),
      // The below portion is required for user input
      formData: {
        itemName: "",
        numServings: "",
        itemPrice: "",
      },
    };
  },
  mounted() {
    //api caling
    axios.get("https://csce315-project3-backend.herokuapp.com/manager/inventory").then((resp) => {
      console.log(resp);
      this.inventory = resp.data;
    });
  },

  methods: {
    // @vuese
    // Author: Matthew, Params: None, Description: Send a query to the database, adding an item to the inventory table.
    addInventory() {
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/inventory/add", this.formData)
        .then((response) =>{
          console.log(response)
          axios.get("https://csce315-project3-backend.herokuapp.com/manager/inventory").then((resp) => {
            console.log(resp);
            this.inventory = resp.data;
          })
        })
        .catch((error) => console.log(error));
    },

    // @vuese
    // Author: Matthew, Params: None, Description: Send a query to the database, updating an item in the inventory table.
    updateInventory() {
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/inventory/update", this.formData)
        .then((response) =>{
          console.log(response)
          axios.get("https://csce315-project3-backend.herokuapp.com/manager/inventory").then((resp) => {
            console.log(resp);
            this.inventory = resp.data;
          })
        })
        .catch((error) => console.log(error));
    },
    //@vuese
    //Sends a query to the database that deletes an inventory item
    deleteInventoryItem(){
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/inventory/delete", this.formData)
        .then((response) =>{
          console.log(response)
          axios.get("https://csce315-project3-backend.herokuapp.com/manager/inventory").then((resp) => {
            console.log(resp);
            this.inventory = resp.data;
          })
        })
        .catch((error) => console.log(error));
    }
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #ffffff;
}
</style>
