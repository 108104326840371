<template>
    <body>
      <div class="home">
        <div class="logopic">
          <p style="opacity:0">spin n stone logo</p>
        </div>
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Directions
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <iframe
                width="600"
                height="450"
                style="border:0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD2454wmAEPBlAwjwv8Mt-PSbU3_gldkCQ
                  &q=Spin+'N+Stone+Pizza+-+MSC,College+Station+TX">
            </iframe>
          </div>
          <div class="menudropdown">
            <button class="dropbtn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Menu
            </button>
            <div class="dropdown-content1">
              <a>1 Topping Pizza<pre class="tab">7.79</pre></a>
              <a>2-4 Topping Pizza<pre class="tab">8.89</pre></a>
              <a>Cheese Pizza<pre class="tab">6.79</pre></a>
              <a>Pepperoni Pizza<pre class="tab">6.79</pre></a>
              <a>Drink<pre class="tab">2.45</pre></a>
              <a>Cauliflower Crust<pre class="tab">+2.99</pre></a>
            </div>
          </div> 
        </div>
        <div>
          
        </div>
      </div>
      
    </body>
  


</template>



<script>

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.home{
  background-image: url('viewsassets/pizzahomepage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 91vh;
  display: grid;
  margin: auto;
}
img{
  width: 100px;
  height: 100px;
}

.btn{
    position: absolute;
    right: 80vw;
    background-color:#E7D428;
    color: black;
    border:none; 
    border-radius:10px; 
    padding:15px;
    min-height:30px; 
    min-width: 120px;
}
.btn:hover {
  background-color: rgb(110, 106, 106);
  color:white;
  transition: .7s;
}
.logopic{
  display: block;
  background-image: url('viewsassets/spinnstonelogowhite.png');
  background-size: cover;
  position: relative;
  right: -60vw;
  top: 35vh;
  width: 40vw;
  height: 30vh;
  
  border: 500px;
  color: red;
}

.dropbtn {
  background-color: #E7D428;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  left: 5%;
  border-radius: 10px;
}

.menudropdown {
  position: relative;
  right: 25%;
  display: inline-block;
}


.dropdown-content1 {
  display: none;
  position: absolute;
  top: -250%;
  left: 100%;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}


.dropdown-content1 a {
  text-align: left;
  color: black;
  left: 100%;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}


.dropdown-content1 a:hover {
  background-color: #ddd;

}

.menudropdown:hover .dropdown-content1 {display: block;}

.menudropdown:hover .dropbtn {
  background-color: rgb(110, 106, 106);
  color: white;
  transition: .7s;
}
</style>
