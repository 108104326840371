<template>
  <div>
    <ManagerNavbar />
  </div>

  <div class="container p-5">
    <h1 class="text-center border-bottom pb-3 mb-3">Past Orders</h1>
    <table class="table table-hover table-bordered mt-5">
      <thead>
        <tr>
          <th scope="col">Order Number</th>
          <th scope="col">Date</th>
          <th scope="col">Price</th>
          <th scope="col">Employee ID</th>
          <th scope="col">Customer ID</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.order_num">
          <td>{{ order.order_num }}</td>
          <td>{{ order.date }}</td>
          <td>{{ order.order_price }}</td>
          <td>{{ order.employee_id }}</td>
          <td>{{ order.customer_id }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ManagerNavbar from "../../components/ManagerNavbar.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
export default {
  name: "Past Orders",
  components: {
    ManagerNavbar,
  },
  data() {
    return {
      orders: [],
    };
  },
  mounted() {
    //api caling
    axios.get("https://csce315-project3-backend.herokuapp.com/manager/pastorders").then((resp) => {
      console.log(resp);
      this.orders = resp.data;
    });
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c4b50;
}

nav a.router-link-exact-active {
  color: #ffffff;
}
</style>
