<template>
    <div id="firebaseui-auth-container"></div>
    <div id="loader">Loading...</div>
    <br>
    <div v-if="isSignedIn">
      <button @click="handleSignOut">Sign Out</button>
    </div>

</template>
  
  <script>
    import "bootstrap/dist/css/bootstrap.min.css";
    import axios from "axios";
    import {ref} from 'vue'
    import firebaseConfig from '../firebaseConfig';
    import firebase from 'firebase/compat/app';
    import * as firebaseui from 'firebaseui'
    import 'firebaseui/dist/firebaseui.css'
    import { getAuth, signOut } from "firebase/auth";

    const f_app = firebase.initializeApp(firebaseConfig);
    const auth = getAuth(f_app);

    export default {
        props: {
        },
        setup(props, {emit}){
            //declare and initialize variables
            const user = ref(null)
            const isSignedIn = ref(false)
            const email = ref("hi")

            const uiConfig = {
                signInFlow: 'popup',
                signinSuccessUrl: 'https://csce315-project3-backend.herokuapp.com/',
                signInOptions: [
                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                ],
                callbacks: {
                    signInSuccessWithAuthResult: function (authResult) {
                        user.value = authResult.user.displayName;
                        console.log(authResult)
                        isSignedIn.value = true;
                        console.log('Signed in by user ' + user.value);
                        email.value = authResult.user.email

                        axios
                            .post("https://csce315-project3-backend.herokuapp.com/cashier/buttons/getCredentials?email=" + email.value)
                            .then((response) => {
                                console.log(response.data)
                                emit("login", response.data)
                            })
                            .catch((error) => console.log(error));
                        
                        
                        
                        return false;
                    },
                    uiShown: function() {
                        document.getElementById('loader').style.display = 'none';
                    }
                }

            }


            var ui = new firebaseui.auth.AuthUI(firebase.auth());

            ui.start('#firebaseui-auth-container', uiConfig);

            //@vuese
            //Signs the user out and signals the app to reset current permission level to null
            const handleSignOut = () => {
                signOut(auth).then(() => {
                // Sign-out successful.
                user.value = null;
                isSignedIn.value = false;
                console.log('Signed out');
                ui.start('#firebaseui-auth-container', uiConfig);
                emit("login", null)
                }).catch((error) => {
                    console.log(error);
                });
            }
            return{
                user,
                isSignedIn,
                handleSignOut,
                email,
            }
        }
    }
  </script>
