<template>
  <div>
    <ManagerNavbar />
  </div>
  <div class="about">
    <!-- Header -->
    <h1>Excess Report</h1>

    <!-- User input field -->
    <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormInputName">Date</label>
        <input
          type="text"
          v-model="formData.date"
          class="form-control"
          id="inlineFormInputName"
          placeholder="mm/dd/yy"
        />
    </div>
  
    <!-- Submit button -->
    <div class="col-auto my-1">
        <button type="submit" @click="generateReport()" class="btn btn-primary">
          Generate Report
        </button>
    </div>

    <!-- Table -->
    <table class="table table-hover table-bordered mt-5">
      <thead>
        <tr>
          <th scope="col">Item</th>
          <th scope="col">Number of Servings</th>
        </tr>
      </thead>
      <tbody>
      
        <tr v-for="item in excess_items" :key="item.item_name">
          <td>{{ item.item_name }}</td>
          <td>{{ item.num_servings }}</td>
        </tr>
      </tbody>

    </table>

  </div>
</template>

<script>
import ManagerNavbar from "../../../components/ManagerNavbar.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

export default {
  name: "ExcessReport",
  components: {
    ManagerNavbar,
  },

  data(){return{
    excess_items: [],
    formData: {
        date: "",
    },
  }},

  methods: {

    // @vuese
    // Author: Matthew, Params: None, Description: Generate the excess report for the manager view.
    generateReport(){
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/reports/excessReport", this.formData)
        .then((response) => {this.excess_items = response.data})
        .catch(err =>{console.log(err)})
    },
    
  }
};
</script>
