<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <router-link class="navbar-brand" to="/manager">Spin n Stone</router-link>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item">
    	<router-link class="nav-link" to="/manager/pastorders">Past Orders</router-link>
      </li>
	<li class="nav-item">
    	<router-link class="nav-link" to="/manager/inventory">Inventory</router-link>
      </li>
	<li class="nav-item">
     	<router-link class="nav-link" to="/manager/menu">Menu</router-link>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Reports
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
    		<router-link class="dropdown-item" to="/manager/reports/excess">Excess Report</router-link>
    		<router-link class="dropdown-item" to="/manager/reports/restock">Restock Report</router-link>
			<router-link class="dropdown-item" to="/manager/reports/sales">Sales Report</router-link>
			<router-link class="dropdown-item" to="/manager/reports/what-sales-together">What Sales Together</router-link>
       
	    </div>
      </li>

    </ul>

  </div>
</nav>
</template>

<script>
export default {
  name: 'ManagerNavbar',
  props: {
    msg: String
  }
}
</script>
