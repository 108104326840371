<template>
<div id="wrapper">
  <div class="home">
    <h1 class="order-here">Order Here</h1>
    <p class="instructions">Please select one item at a time</p>
    <button
      @click="open = true; blurBackground()"
      type="button"
      class="btn btn-warning cart_open"
    >
      Cart <span class="badge badge-danger">{{ numItems }}</span>
    </button>
    <Teleport to="body">
      <div
        v-if="open"
        class="popupWindow border border-warning rounded-top rounded-bottom"
      >
        <button class="cart_close" @click="open = false; unblurBackground()" style="float: right">
          X
        </button>
        <p class="Order_ticket">Order Ticket</p>
        <hr />
        <p class="order-contents" v-for="item in orderItems" :key="item">
          {{ item }}
        </p>

        <div id="bottom">
          <p>
            Subtotal: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${{
              orderTotal
            }}
          </p>
          <p>
            Estimated Tax: ${{ Math.round(orderTotal * 0.0625 * 100) / 100 }}
          </p>
          <p>
            Total:
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ${{ orderTotalWithTax }}
          </p>
        </div>

        <button class="checkout btn btn-warning" @click="submitOrder">Checkout</button>
      </div>
    </Teleport>

    <button class="menu_open" @click="open2 = true; blurBackground()">Menu</button>
    <Teleport to="body">
      <div v-if="open2" class="popupWindow_menu">
        <p class="Menu">Menu</p>
        <div class="menu_contents">
          <p class="menu_cheese">
            Original Cheese Pizza -------- $6.79
          </p>
          <p class="menu_pepperoni">
            Pepperoni Pizza -------------- $6.79
          </p>
          <p class="menu_1_top">
            1 Topping Pizza -------------- $7.79
          </p>
          <p class="menu_2_4_top">
            2-4 Topping Pizza ------------ $8.99
          </p>
          <p class="menu_cauliflower_crust">
            Cauliflower Crust ------------- $2.45
          </p>
          <p class="menu_drink">
            Drink -------------------------- $2.99
          </p>
        </div>
        <p class="Toppings">Toppings</p>
        <div class="topping_contents">
          <p class="Meats">Meats</p>
          <div class="Meats_contents">
            <p class="meats1">
              Diced Ham | Italian Sausage | Meatball
            </p>
            <p class="meats2">
              Pepperoni | Salami | Smoked Chicken
            </p>
          </div>
          <p class="Veggies">Veggies</p>
          <div class="Veggies_contents">
            <p class="veggies1">
              Banana Peppers | Black Olives | Green Peppers
            </p>
            <p class="veggies2">
              Jalapenos | Mushrooms | Onions | Pineapple
            </p>
            <p class="veggies3">
              Roasted Garlic | Spinach | Tomatoes
            </p>
          </div>
        </div>
        <button class="menu_close" @click="open2 = false; unblurBackground()">X</button>
      </div>
    </Teleport>

    <div class="nav flex-row menu-body">
      <div
        class="nav flex-column nav-pills menu-navbar"
        id="v-pills-tab"
        style="width: 150px"
        role="tablist"
        aria-orientation="vertical"
      >
        <a
          class="nav-link active"
          id="v-pills-home-tab"
          data-toggle="pill"
          href="#v-pills-home"
          role="tab"
          aria-controls="v-pills-home"
          aria-selected="true"
          >Choose your item</a
        >
        <a
          class="nav-link"
          id="v-pills-profile-tab"
          data-toggle="pill"
          href="#v-pills-profile"
          style="display: none"
          role="tab"
          aria-controls="v-pills-profile"
          aria-selected="false"
          >Choose your crust</a
        >
        <a
          class="nav-link"
          id="v-pills-messages-tab"
          data-toggle="pill"
          href="#v-pills-messages"
          style="display: none"
          role="tab"
          aria-controls="v-pills-messages"
          aria-selected="false"
          @click="disableButtons"
          >Choose your toppings</a
        >
        <a
          class="nav-link"
          id="v-pills-settings-tab"
          data-toggle="pill"
          href="#v-pills-settings"
          role="tab"
          aria-controls="v-pills-settings"
          aria-selected="false"
          @click="
            tempView();
            resetbtnstate();
          "
          >Add item to order</a
        >
      </div>
      <div class="tab-content flex-column" id="v-pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="v-pills-home"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <button
            type="button"
            class="btn btn-light Original_cheese"
            data-toggle="button"
            @click="
              addCheese();
              selectCheesePizza();
            "
          >
            <img src="../../components/Original_cheese.jpg" /><br />Original
            Cheese Pizza
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Pepperoni_pizza item"
            data-toggle="button"
            @click="
              addPepperoni();
              selectPepperoniPizza();
            "
          >
            <img src="../../components/Pepperoni_pizza.jpg" /><br />Pepperoni
            Pizza
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light one_topping item"
            data-toggle="button"
            @click="selectOneToppingPizza"
          >
            <img src="../../components/1_topping.jpg" /><br />1 Topping Pizza
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light two_four_topping item"
            data-toggle="button"
            @click="selectTwoFourToppingPizza"
          >
            <img src="../../components/2_4_topping.jpg" /><br />2-4 Topping
            Pizza
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Drink item"
            data-toggle="button"
            @click="addDrink"
          >
            <img src="../../components/Drink.jpg" /><br />Drink
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light seasonal item"
            data-toggle="button"
            @click="addSeasonal"
          >
            <img src="../../components/seasonal.jpg" /><br />{{ seasonal_name }}
          </button>
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-profile"
          role="tabpanel"
          aria-labelledby="v-pills-profile-tab"
        >
          &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
          &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
          &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Cauliflower_crust"
            data-toggle="button"
            @click="addCauliflowerCrust"
          >
            <img
              src="../../components/Cauliflower_crust.jpg"
            /><br />Cauliflower Crust
          </button>
          &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
          &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;

          <button
            type="button"
            class="btn btn-light Cauliflower_crust"
            data-toggle="button"
          >
            <img src="../../components/regular-crust.jpg" /><br />Regular Crust
          </button>
          &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
          &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
          &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-messages"
          role="tabpanel"
          aria-labelledby="v-pills-messages-tab"
        >
          <button
            type="button"
            class="btn btn-light Diced_ham toppings"
            data-toggle="button"
            @click="addDicedHam"
          >
            <img src="../../components/Diced_ham.jpg" /><br />Diced Ham
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Italian_sausage toppings"
            data-toggle="button"
            @click="addItalianSausage"
          >
            <img src="../../components/Italian_sausage.jpg" /><br />Italian
            Sausage
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Meatball toppings"
            data-toggle="button"
            @click="addMeatball"
          >
            <img src="../../components/Meatball.jpg" /><br />Meatball
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Pepperoni toppings"
            data-toggle="button"
            @click="addPepperoni"
          >
            <img src="../../components/Pepperoni.jpg" /><br />Pepperoni
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Salami toppings"
            data-toggle="button"
            @click="addSalami"
          >
            <img src="../../components/Salami.jpg" /><br />Salami
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Smoked_chicken toppings"
            data-toggle="button"
            @click="addSmokedChicken"
          >
            <img src="../../components/Smoked_chicken.jpg" /><br />Smoked
            Chicken
          </button>
            &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Banana_peppers toppings"
            data-toggle="button"
            @click="addBananaPeppers"
          >
            <img src="../../components/Banana_peppers.jpg" /><br />Banana
            Peppers
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Black_olives toppings"
            data-toggle="button"
            @click="addBlackOlives"
          >
            <img src="../../components/Black_olives.jpg" /><br />Black Olives
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Green_peppers toppings"
            data-toggle="button"
            @click="addGreenPeppers"
          >
            <img src="../../components/Green_peppers.jpg" /><br />Green Peppers
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Jalepenos toppings"
            data-toggle="button"
            @click="addJalapenos"
          >
            <img src="../../components/Jalepenos.jpg" /><br />Jalapenos
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Mushrooms toppings"
            data-toggle="button"
            @click="addMushrooms"
          >
            <img src="../../components/Mushrooms.jpg" /><br />Mushrooms
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Onions toppings"
            data-toggle="button"
            @click="addOnions"
          >
            <img src="../../components/Onions.jpg" /><br />Onions
          </button>
          &nbsp; &nbsp; &nbsp;

          <button
            type="button"
            class="btn btn-light Pineapple toppings"
            data-toggle="button"
            @click="addPineapple"
          >
            <img src="../../components/Pineapples.jpg" /><br />Pineapple
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Roasted_garlic toppings"
            data-toggle="button"
            @click="addRoastedGarlic"
          >
            <img src="../../components/Roasted_garlic.jpg" /><br />Roasted
            Garlic
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Spinach toppings"
            data-toggle="button"
            @click="addSpinich"
          >
            <img src="../../components/Spinach.jpg" /><br />Spinach
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            type="button"
            class="btn btn-light Tomatoes toppings"
            data-toggle="button"
            @click="addTomatoes"
          >
            <img src="../../components/Tomatoes.jpg" /><br />Tomatoes
          </button>
        </div>

        <div
          class="tab-pane fade"
          id="v-pills-settings"
          role="tabpanel"
          aria-labelledby="v-pills-settings-tab"
        >
          <div class="d-flex flex-row">
            <table class="table p-2">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ itemName }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table p-2">
              <thead>
                <tr>
                  <th scope="col">Toppings</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in toppings" :key="row">
                  <td>{{ row }}</td>
                </tr>
              </tbody>
            </table>

            <table class="table p-2">
              <thead>
                <tr>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ itemPrice }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Btn_additem :onClick="generateOrderView">Add Item</Btn_additem>
          <button
            type="button"
            class="btn btn-outline-danger start-over"
            @click="removeItem"
          >
            Remove Item
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>



<script>
import Btn_seasonal from "../../components/Btn_seasonal.vue";
import Btn_additem from "../../components/Btn_additem.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

export default {
  name: "CustomerView",
  components: {
    Btn_seasonal,
    Btn_additem,
  },
  data() {
    return {
      open: false,
      open2: false,
      toppings: [],
      orderItems: [],
      itemQueries: [],
      addCauliflower: false,
      addCauliQuery: "FALSE",
      isCheese: false,
      numItems: 0,
      numToppings: 0,
      isDrink: false,
      itemName: "",
      maxOrderItemID: 0,
      maxOrderNumID: 0,
      itemPrice: 0,
      orderTotal: 0,
      maxToppings: 4,
      orderIDCounter: 0,
      orderTotalWithTax: 0,
      seasonal_name: "",
      isSeasonal: false,
    };
  },
  mounted() {
    axios
      .post("https://csce315-project3-backend.herokuapp.com/cashier/buttons/getName")
      .then((response) => {
        console.log(response);
        this.seasonal_name = response.data;
      })
      .catch((error) => console.log(error));
  },

  methods: {
    //@vuese
    //Function disables toppings because it is a cheese pizza
    selectCheesePizza() {
      this.disableItems();
      this.maxToppings = 0;
      this.showCrustElement();

    },
    //@vuese
    //Function disables toppings because it is a pepperoni pizza
    selectPepperoniPizza() {
      this.disableItems();
      this.maxToppings = 1;
      this.showCrustElement();
    },
    //@vuese
    //Function allows only 1 topping to be selected
    selectOneToppingPizza() {
      this.disableItems();
      this.maxToppings = 1;
      this.showCrustElement();
      this.showToppingElement();


    },
    //@vuese
    //Function allows only up to 4 toppings to be selected
    selectTwoFourToppingPizza() {
      this.disableItems();
      this.maxToppings = 4;
      this.showCrustElement();
      this.showToppingElement();
    },

    //@vuese
    //adds selected topping to topping array
    addDicedHam() {
      this.toppings.push("Diced Ham");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addItalianSausage() {
      this.toppings.push("Italian Sausage");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addMeatball() {
      this.toppings.push("Meatball");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addPepperoni() {
      this.toppings.push("Pepperoni");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addSalami() {
      this.toppings.push("Salami");
      this.numToppings++;
      this.disableButtons();
    },

    //@vuese
    //adds selected topping to topping array
    addSmokedChicken() {
      this.toppings.push("Smoked Chicken");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addBananaPeppers() {
      this.toppings.push("Banana Peppers");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addBlackOlives() {
      this.toppings.push("Black Olives");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addGreenPeppers() {
      this.toppings.push("Green Peppers");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addJalapenos() {
      this.toppings.push("Jalapenos");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addMushrooms() {
      this.toppings.push("Mushrooms");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addOnions() {
      this.toppings.push("Onions");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addPineapple() {
      this.toppings.push("Pineapple");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addRoastedGarlic() {
      this.toppings.push("Roasted Garlic");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addSpinich() {
      this.toppings.push("Spinach");
      this.numToppings++;
      this.disableButtons();
    },
    //@vuese
    //adds selected topping to topping array
    addTomatoes() {
      this.toppings.push("Tomatoes");
      this.numToppings++;
      this.disableButtons();
    },

    //@vuese
    //sets bool cauli crust to true
    addCauliflowerCrust() {
      this.addCauliflower = true;
      this.addCauliQuery = "TRUE";
    },

    //@vuese
    //sets drink variable to true
    addDrink() {
      this.isDrink = true;
    },
    //@vuese
    //sets seasonal variable to true
    addSeasonal() {
      this.isSeasonal = true;
    },
    addCheese() {
      this.isCheese = true;
    },
    //@vuese
    //function blurs webpage
    blurBackground() {
      var x = document.querySelector('.menu-body')
      x.style.filter = "blur(5px)"

    },
    //@vuese
    //function unblurs background
    unblurBackground() {
      var x = document.querySelector('.menu-body')
      x.style.filter = "blur(0px)"
    },
    //@vuese
    //function hides and shows select toppings button
    showToppingElement() {
      var x = document.getElementById("v-pills-messages-tab");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }

    },
    //@vuese
    //function hides and shows select crust button
    showCrustElement() {
      var x = document.getElementById("v-pills-profile-tab");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }

    },
    //@vuese
    //function hides select topping button
    hideToppingElement() {
      var x = document.getElementById("v-pills-messages-tab");
      x.style.display = "none";  
    },

    //@vuese
    //function hides select crust button
    hideCrustElement() {
      var x = document.getElementById("v-pills-profile-tab");
      x.style.display = "none";      
    },
    //@vuese
    //Function resets all button states
    resetbtnstate() {
      $("button").removeClass("active");
      $("button").prop("disabled", false);
    },
    //@vuese
    //function disables all buttons except the ones selected
    async disableButtons() {
      //$('button').prop("disabled", true)
      if (this.numToppings == this.maxToppings) {
        setTimeout(
          () => $("button").not(".active").prop("disabled", true),
          300
        );
      }
    },
    //@vuese
    //resets all parameters
    removeItem() {
      this.isSeasonal = false;
      this.isDrink = false;
      this.addCauliflower = false;
      this.addCauliQuery = "FALSE";
      this.toppings.splice(0);
      this.numToppings = 0;
      this.itemName = "";
      this.maxToppings = 4;
      this.itemPrice = 0;
      this.isCheese = false;
      this.resetbtnstate();
      this.tempView();
      this.hideToppingElement();
      this.hideCrustElement();
    },
    disableItems() {
      setTimeout(() => $("item").not(".active").prop("disabled", true), 300);
    },
    reloadPage() {
      window.location.reload();
    },
    //@vuese
    //Creates a psuedo view of current item before adding to order
    async tempView() {
      // gets most recent order num & order item num
      await this.getMaxOrderItemID();
      await this.getMaxOrderNumID();

      //IF SEASONAL ITEM
      if (this.isSeasonal) {
        this.itemName = this.seasonal_name;
        await this.getItemPrice(this.seasonal_name);
      }

      // IF DRINK
      else if (this.isDrink) {
        this.itemName = "Fountain Drink";
        await this.getItemPrice("Fountain Drink");
      } else {
        // IF CHEESE PIZZA
        if (this.toppings == 0 && !this.isCheese) {
          this.itemName = "No Item Selected";
          this.itemPrice = 0;
        } else if (this.numToppings == 0 && this.isCheese) {
          this.itemName = "Original Cheese Pizza";
          await this.getItemPrice("Original Cheese Pizza");

          if (this.addCauliflower) {
            this.itemName = "Cheese Pizza w/ Cauliflower";
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;
          } else {
          }
        }
        // IF PEPPERONI PIZZA
        else if (this.numToppings == 1 && this.toppings[0] == "Pepperoni") {
          this.itemName = "Pepperoni Pizza";
          await this.getItemPrice("Pepperoni Pizza");

          if (this.addCauliflower) {
            this.itemName = "Pepperoni Pizza w/ Cauliflower";
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;
          } else {
          }

          // IF ONE TOPPING PIZZA
        } else if (this.numToppings == 1) {
          this.itemName = "1 Topping Pizza";
          await this.getItemPrice("1 Topping Pizza");

          // determines output
          if (this.addCauliflower) {
            this.itemName = "1 Topping Pizza w/ Cauliflower";
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;
          } else {
          }
          // add ingredients to array

          this.orderItems[this.numItems] += " " + this.toppings[0];

          // ELSE, 2 TOPPING PIZZA
        } else {
          this.itemName = "2-4 Topping Pizza";
          await this.getItemPrice("2-4 Topping Pizza");
          if (this.addCauliflower) {
            this.itemName = "2-4 Topping Pizza w/ Cauliflower";
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;
          } else {
          }
        }
      }
    },

    //@vuese
    //generates the order view for website
    async generateOrderView() {
      // gets most recent order num & order item num
      await this.getMaxOrderItemID();
      await this.getMaxOrderNumID();

      //IF SEASONAL ITEM
      if (this.isSeasonal) {
        this.itemName = this.seasonal_name;
        await this.getItemPrice(this.seasonal_name);
        this.orderItems[this.numItems] =
          this.seasonal_name + " --------------------------- " + this.itemPrice;
      }

      // IF DRINK
      else if (this.isDrink) {
        this.itemName = "Fountain Drink";
        await this.getItemPrice("Fountain Drink");
        this.orderItems[this.numItems] =
          "Drink ------------------------------------------------- " + this.itemPrice;
      } else {
        // IF CHEESE PIZZA
        if (this.numToppings == 0) {
          this.itemName = "Original Cheese Pizza";
          await this.getItemPrice("Original Cheese Pizza");

          if (this.addCauliflower) {
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;
            this.orderItems[this.numItems] =
              "Cheese Pizza w/ Cauliflower --------------------------- " +
              this.itemPrice;
          } else {
            this.orderItems[this.numItems] =
              "Cheese Pizza ------------------------------------------ " +
              this.itemPrice;
          }
        }
        // IF PEPPERONI PIZZA
        else if (this.numToppings == 1 && this.toppings[0] == "Pepperoni") {
          this.itemName = "Pepperoni Pizza";
          await this.getItemPrice("Pepperoni Pizza");

          if (this.addCauliflower) {
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;
            this.orderItems[this.numItems] =
              "Pepperoni Pizza w/ Cauliflower ------------------------ " +
              this.itemPrice;
          } else {
            this.orderItems[this.numItems] =
              "Pepperoni Pizza --------------------------------------- " +
              this.itemPrice;
          }

          // IF ONE TOPPING PIZZA
        } else if (this.numToppings == 1) {
          this.itemName = "1 Topping Pizza";
          await this.getItemPrice("1 Topping Pizza");

          // determines output
          if (this.addCauliflower) {
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;

            this.orderItems[this.numItems] =
              "1 Topping Pizza w/ Cauliflower ------------------------- " +
              this.itemPrice;
          } else {
            this.orderItems[this.numItems] =
              "1 Topping Pizza  ------------------------------------------ " +
              this.itemPrice;
          }
          // add ingredients to array

          this.orderItems[this.numItems] += " " + this.toppings[0];

          // ELSE, 2 TOPPING PIZZA
        } else {
          this.itemName = "2-4 Topping Pizza";
          await this.getItemPrice("2-4 Topping Pizza");
          if (this.addCauliflower) {
            this.itemPrice += 2.99;
            this.itemPrice = Math.round(this.itemPrice * 100) / 100;
            this.orderItems[this.numItems] =
              "2-4 Topping Pizza w/ Cauliflower --------------------- " +
              this.itemPrice;
          } else {
            this.orderItems[this.numItems] =
              "2-4 Topping Pizza -------------------------------------- " +
              this.itemPrice;
          }

          // appending toppings to order window
          for (var i = 0; i < this.numToppings; i++) {
            if (i + 1 == this.numToppings) {
              this.orderItems[this.numItems] += " " + this.toppings[i];
              continue;
            }
            this.orderItems[this.numItems] += " " + this.toppings[i] + ", ";
          }
        }
      }

      this.orderIDCounter++;
      this.orderTotal += this.itemPrice; // price to item total
      this.orderTotalWithTax = this.orderTotal * 1.0625; // appends to order total with tax
      this.orderTotalWithTax = Math.round(this.orderTotalWithTax * 100) / 100;
      await this.populateQueries();

      this.numItems++;
      this.isSeasonal = false;
      this.isDrink = false;
      this.addCauliflower = false;
      this.addCauliQuery = "FALSE";
      this.toppings.splice(0);
      this.numToppings = 0;
      this.isCheese = false;
      this.resetbtnstate();
      this.tempView();
      this.hideToppingElement();
      this.hideCrustElement();
    },

    //@vuese
    //gets max order item id
    async getMaxOrderItemID() {
      await axios
        .post("https://csce315-project3-backend.herokuapp.com/cashier/order-item")
        .then((resp) => {
          console.log(resp);
          this.maxOrderItemID = resp.data.max;
        });
    },

    //@vuese
    //submits a post request that returns the max order number
    async getMaxOrderNumID() {
      await axios
        .post("https://csce315-project3-backend.herokuapp.com/cashier/order-num")
        .then((resp) => {
          console.log(resp);
          this.maxOrderNumID = resp.data.max;
        });
    },


    //@vuese
    //submits a post request that returns the specified item price
    async getItemPrice(item_name) {
      await axios
        .post("https://csce315-project3-backend.herokuapp.com/cashier/item-price", {
          itemName: item_name,
        })
        .then((response) => {
          console.log(response);
          this.itemPrice = response.data.item_price;
        })
        .catch((error) => console.log(error));
    },

    //@vuese
    //creates a sql query for the added item and appends to list
    async populateQueries() {
      this.itemQueries[this.numItems] =
        "INSERT INTO order_items(order_item_id, order_item_name, price_at_time_of_purchase, " +
        "ingredient1_name, ingredient2_name, ingredient3_name, ingredient4_name, use_cauliflower_crust, order_num) VALUES(";

      this.itemQueries[this.numItems] +=
        this.maxOrderItemID +
        this.orderIDCounter +
        ",'" +
        this.itemName +
        "', " +
        this.itemPrice +
        ", ";

      for (var i = 0; i < this.numToppings; i++) {
        this.itemQueries[this.numItems] += "'" + this.toppings[i] + "', "; // appends ingredient to query
      }

      while (this.numToppings < 4) {
        this.itemQueries[this.numItems] += "'NULL', ";
        this.numToppings++;
      }

      this.itemQueries[this.numItems] +=
        "'" + this.addCauliQuery + "', " + (this.maxOrderNumID + 1) + ");";
    },



    // @vuese
    // Author: Truett, Params: hello, Description: submits item list queries & order query and calls submit query to database, then clears all variables
    async submitOrder() {
      // gets current date
      const current = new Date();
      const date = `${current.getMonth() + 1}/${current.getDate()}/${current
        .getFullYear()
        .toString()
        .substr(-2)}`;

      // rounds order total to 2 decimal places
      this.orderTotal *= 1.0625;
      this.orderTotal = Math.round(this.orderTotal * 100) / 100;
      var employee_id = 50;

      const order_query =
        "INSERT INTO orders(order_num, date, order_price, customer_id, employee_id)" +
        "VALUES (" +
        (this.maxOrderNumID + 1) +
        ",'" +
        date +
        "'," +
        this.orderTotal +
        ", " +
        (this.maxOrderNumID + 1) +
        ", " +
        employee_id +
        ");";

      // submits all the item queries
      for (var i = 0; i < this.numItems; i++) {
        await this.submitQuery(this.itemQueries[i]);
      }

      //submits the order query
      await this.submitQuery(order_query);

      // reset values
      alert(`Submitted order, total was ${this.orderTotal}`);
      this.orderTotal = 0;
      this.orderIDCounter = 0;
      this.orderTotalWithTax = 0;
      this.itemQueries.splice(0);
      this.orderItems.splice(0);
      await this.reloadPage();
    },

    //@vuese
    //executes passed query
    async submitQuery(currQuery) {
      await axios
        .post("https://csce315-project3-backend.herokuapp.com/cashier/submit-query", {
          query: currQuery,
        })
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    },
    async reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.Original_cheese img {
  width: 150px;
  height: 150px;
}
.Pepperoni_pizza img {
  width: 150px;
  height: 150px;
}
.one_topping img {
  width: 150px;
  height: 150px;
}
.two_four_topping img {
  width: 150px;
  height: 150px;
}
.Diced_ham img {
  width: 150px;
  height: 150px;
}
.Italian_sausage img {
  width: 150px;
  height: 150px;
}
.Meatball img {
  width: 150px;
  height: 150px;
}
.Pepperoni img {
  width: 150px;
  height: 150px;
}
.Salami img {
  width: 150px;
  height: 150px;
}
.Smoked_chicken img {
  width: 150px;
  height: 150px;
}
.Banana_peppers img {
  width: 150px;
  height: 150px;
}
.Black_olives img {
  width: 150px;
  height: 150px;
}
.Green_peppers img {
  width: 150px;
  height: 150px;
}
.Jalepenos img {
  width: 150px;
  height: 150px;
}
.Mushrooms img {
  width: 150px;
  height: 150px;
}
.Onions img {
  width: 150px;
  height: 150px;
}
.Pineapple img {
  width: 150px;
  height: 150px;
}
.Roasted_garlic img {
  width: 150px;
  height: 150px;
}
.Spinach img {
  width: 150px;
  height: 150px;
}
.Tomatoes img {
  width: 150px;
  height: 150px;
}
.Cauliflower_crust img {
  width: 150px;
  height: 150px;
}
.Drink img {
  width: 150px;
  height: 150px;
}
.seasonal img {
  width: 150px;
  height: 150px;
}

.menu-body {
  margin-left: 10%;
  filter: blur(0px);
  

}
.menu-navbar {
  padding-top: 10%;
  background-color: #e7d428;
  padding-right: 9em;
  padding-left: 3em;
  padding-bottom: 10%;
}
.popupWindow {
  border-width: thick;
  position: fixed;
  z-index: 999;
  top: 20%;
  left: 48%;
  width: 500px;
  height: 600px;
  margin-left: -225px;
  background-color: rgb(255, 255, 255);
}
.cart_open {
  position: fixed;
  top: 100px;
  right: 50px;
}
.cart_close {
  text-align: right;
  top: 200px;
  right: 625px;
  background: rgb(255, 51, 0);
  border: 2px solid rgb(255, 51, 0);
  color: rgb(255, 255, 255);
  font-style: bold;
}
.Order_ticket {
  margin-top: 3em;
  text-align: center;

  font-size: large;
  font-style: bold;
}
.checkout {
  position: absolute;
  bottom: 5%;
  left: 50%;
  margin-left: -35px !important;

  font-style: bold;
}
.order-contents {
  padding-left: 5%;
  padding-right: 5%;
}
.popupWindow_menu{
  position:fixed;
  z-index:999;
  top: 20%;
  left: 48%;
  width: 500px;
  height: 750px;
  margin-left: -225px;
  background-color: rgb(249, 223, 171);
}
.menu_open{
  position:fixed;
  top: 110px;
  left: 50px;
  background-color: rgb(255, 191, 63);
  border: none;
  padding: 7px 10px;
  border-radius: 10px;
}
.menu_close{
  position: fixed;
  top: 21%;
  right: 37%;
  background: rgb(255, 51, 0);
  border: 2px solid rgb(255, 51, 0);
  color: rgb(255, 255, 255);
  font-style: bold;
}
.Menu{
  position: fixed;
  top: 20%;
  right: 48%;
  font-size: xx-large;
  font-weight: bold;
}
.menu_contents{
  position:fixed;
  top: 25%;
  right: 43%;
  font-style: x-large;
  font-style: bold;
}
.menu_cheese{
  font-style: x-large;
  font-weight: bold;
}
.menu_pepperoni{
  font-style: x-large;
  font-weight: bold;
}
.menu_1_top{
  font-style: x-large;
  font-weight: bold;
}
.menu_2_4_top{
  font-style: x-large;
  font-weight: bold;
}
.menu_cauliflower_crust{
  font-style: x-large;
  font-weight: bold;
}
.menu_drink{
  font-style: x-large;
  font-weight: bold;
}
.Toppings{
  position: fixed;
  top: 50%;
  right: 47%;
  font-size: xx-large;
  font-weight: bold;
}
.topping_contents{
  position: fixed;
  top: 55%;
  right: 40%;
  font-style: large;
}
.Meats{
  font-style: x-large;
  font-weight: bold;
}
.Veggies{
  font-style: x-large;
  font-weight: bold;
}
.instructions{
  font-style: x-large;
  font-weight: bold;
}

#bottom {
  position: absolute;
  bottom: 4.5rem;
  left: 50%;
  margin-left: -60px;

  font-size: 1.2em;
  line-height: 50%;
}

.table-column {
  padding-top: 200px;
  padding-left: 80px;
}
.nav-link {
  background-color: beige;
  margin: 10px 0 10px 0;
}
.tab-content {
  background-color: beige;
  padding: 100px 50px 0 100px;
}
.order-here {
  padding: 20px 20px 20px 20px;
}

.start-over {
  display: inline-block;
  margin: 0.5em 0;
  padding: 1em 2em;
  background: #fff;
  border: 2px solid red;
  border-radius: 3px;
  color: red;
  font-family: "Quicksand", sans-serif;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  text-decoration-color: black;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 50px;
}

.start-over:hover {
      background: rgb(255, 51, 0);
      color: rgb(255, 255, 255);
    }

.flex-row {
  flex-wrap: nowrap;
}
.btn {
  margin: 10px 10px 10px 10px;
}
#v-pills-tabContent {
  margin-right: 50px;
}

#v-pills-settings {
  padding-left: 350px;
  padding-right: 350px;
}

/* #v-pills-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */
</style>