<template>
  <div>
    <nav class="navbar navbar-inverse">
      <div class="container-fluid">
        <ul>
          <li v-if="permission == 'Cashier' || permission == 'Manager'" class="active"><a class="navbar a"><router-link to="/">Home</router-link></a></li>
          <li v-if="permission == 'Manager'" class="b"><a class="navbar a"><router-link to="/customer">Customer</router-link></a></li>
          <li v-if="permission == 'Cashier' || permission == 'Manager'" class="b"><a class="navbar a"><router-link to="/cashier">Cashier</router-link></a></li>
          <li v-if="permission == 'Manager'" class="b"><a class="navbar a"><router-link to="/manager">Manager</router-link></a></li>
        </ul>
        <ul class="navbarimg">
          <a style="opacity:0">Spin n Stone</a>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li><Login_Btn @login='setView'>Login</Login_Btn></li>
        </ul>
      </div>
    </nav>
  </div>
  <router-view />


</template>

<script>
import Login_Btn from './components/Login_Btn.vue'
import {ref} from 'vue'

export default ({
  data(){
    return{
      permission: ref(null)
    }
  },
  components:{
    Login_Btn
  },

  methods:{
    setView(user_permission){
      //@vuese
      //sets permission variable to logged in user's permission value
      this.permission = user_permission
    }
  }

})
</script>




<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  display: inline-flex;
  background: #E7D428;
  /* padding: 30px; */
}
nav ul{
  display: inline;
  color: red;
}
.b{
  display: inline-block;
  padding: 0px 15px;
  width: 25%;
}
.navbar a:link{
  text-decoration: none;
}
li{
  display: inline-block;
  color: aqua;
}

ul{
  color: green;
  margin: 0;
  padding: 0;
}
.navbarimg{
  background-image: url('assets/spinnstonelogo\(2\).png');
  background-size:100%;
  margin-right: 15%; 
  padding-top: 8px;
  padding-bottom: 8px;
  
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #ffffff;
}
</style>
