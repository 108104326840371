<template>
  <div>
    <ManagerNavbar />
  </div>

  <div class="container p-5">
    <h1 class="text-center border-bottom pb-3 mb-3">Menu</h1>

<!-- These lines create the text fields for user input -->
<p>Add/update menu item:</p>
    <div class="row justify-content-center">
      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormInputName">Item Number</label>
        <input
          type="text"
          v-model="formData.itemNum"
          class="form-control"
          id="inlineFormInputName"
          placeholder="Item Number"
        />
      </div>

      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormInputName"
          >Item Name</label
        >
        <input
          type="text"
          v-model="formData.itemName"
          class="form-control"
          id="inlineFormInputServings"
          placeholder="Item Name"
        />
      </div>
      <div class="col-sm-3 my-1">
        <label class="sr-only" for="inlineFormInputName">Item Price</label>
        <input
          type="text"
          v-model="formData.itemPrice"
          class="form-control"
          id="inlineFormInputPrice"
          placeholder="Item Price"
        />
      </div>
      <br>
      <div class="col-auto my-1">
        <button
          type="submit"
          @click="
            addMenu();

          "
          class="btn btn-primary"
        >
          Add
        </button>
      </div>
      <div class="col-auto my-1">
        <button
          type="submit"
          @click="
            updateMenu();
          "
          class="btn btn-primary"
        >
          Update
        </button>
      </div>
      <div class="col-auto my-1">
        <button
          type="submit"
          @click="
            addSeasonal();
          "
          class="btn btn-primary"
        >
          Add Seasonal Item
        </button>
      </div>
      <div class="col-auto my-1">
        <button
          type="submit"
          @click="
            deleteMenuItem();
          "
          class="btn btn-primary"
        >
          Delete
        </button>
      </div>
    </div>

    <table class="table table-hover table-bordered mt-5">
      <thead>
        <tr>
          <th scope="col">Item Number</th>
          <th scope="col">Item</th>
          <th scope="col">Item Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in menu" :key="item.item_num">
          <td>{{ item.item_num }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.item_price }}</td>        
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ManagerNavbar from "../../components/ManagerNavbar.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import {ref} from 'vue'

export default {
  name: "Menu",
  components: {
    ManagerNavbar,
  },
  data() {
    return {
      menu: ref([]),
      // The below portion is required for user input
      formData: {
        itemNum: "",
        itemName: "",
        itemPrice: "",
      },
    };
  },
  mounted() {
    //api caling
    axios.get("https://csce315-project3-backend.herokuapp.com/manager/menu").then((resp) => {
      this.menu = resp.data;
    });
  },

  methods: {
    // @vuese
    // Author: Matthew, Params: None, Description: Send a query to the database, adding an item to the menu table.
    addMenu() {
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/menu/add", this.formData)
        .then((response) => {
          console.log(response)
          axios.get("https://csce315-project3-backend.herokuapp.com/manager/menu").then((resp) => {
            this.menu = resp.data;
          });
          
        })
        .catch((error) => console.log(error));
    },
    //@vuese
    //Sends a query to the database that adds a seasonal item to menu
    addSeasonal(){
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/menu/addSeasonal", this.formData)
        .then((response) => {
          console.log(response)
          axios.get("https://csce315-project3-backend.herokuapp.com/manager/menu").then((resp) => {
            this.menu = resp.data;
          });
        })
        .catch((error) => console.log(error));
    },


    // @vuese
    // Author: Matthew, Params: None, Description: Send a query to the database, updating an item in the inventory table.
    updateMenu() {
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/menu/update", this.formData)
        .then((response) => {
          console.log(response)
          axios.get("https://csce315-project3-backend.herokuapp.com/manager/menu").then((resp) => {
            this.menu = resp.data;
          });
        })
        .catch((error) => console.log(error));
    },
    //@vuese
    //Sends a query to the database that deletes a menu item
    deleteMenuItem(){
      axios
        .post("https://csce315-project3-backend.herokuapp.com/manager/menu/delete", this.formData)
        .then((response) => {
          console.log(response)
          axios.get("https://csce315-project3-backend.herokuapp.com/manager/menu").then((resp) => {
            this.menu = resp.data;
          });
        })
        .catch((error) => console.log(error));
    }
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
