import { createRouter, createWebHistory } from 'vue-router'
import CashierView from '../views/cashier/CashierView.vue'
import AboutView from '../views/manager/PastOrders.vue'
import Inventory from '../views/manager/Inventory.vue'
import Menu from '../views/manager/Menu.vue'
import PastOrders from '../views/manager/PastOrders.vue'
import ExcessReport from '../views/manager/reports/ExcessReport.vue'
import RestockReport from '../views/manager/reports/RestockReport.vue'
import SalesReport from '../views/manager/reports/SalesReport.vue'
import WhatSalesTogether from '../views/manager/reports/WhatSalesTogether.vue'
import HomeView from '../views/HomeView.vue'
import CustomerView from '../views/customer/CustomerView.vue'



const routes = [

  {
    path:'/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cashier',
    name: 'cashier',
    component: CashierView
  },
  {
    path: '/customer',
    name: 'customer',
    component: CustomerView
  },

  {
    path: '/manager',
    name: 'manager',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/manager/inventory',
    name: 'inventory',
    component: Inventory
  },
  {
    path: '/manager/menu',
    name: 'menu',
    component: Menu
  },
  {
    path: '/manager/pastorders',
    name: 'past-orders',
    component: PastOrders
  },
  {
    path: '/manager/reports/excess',
    name: 'excess-report',
    component: ExcessReport
  },
  {
    path: '/manager/reports/restock',
    name: 'restock',
    component: RestockReport
  },
  {
    path: '/manager/reports/sales',
    name: 'sales',
    component: SalesReport
  },
  {
    path: '/manager/reports/what-sales-together',
    name: 'what-sales-together',
    component: WhatSalesTogether
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
