<template>
    <button class="button">
      <div>
        <v-btn @click="onClick" pill>{{seasonal_name}}</v-btn>
      </div>
    </button>
  </template>
  
  <script>
    import "bootstrap/dist/css/bootstrap.min.css";
    import axios from "axios";
    export default {
      props: {
        onClick: {
          type: Function,
          required: true,
        }
      },
          
      data(){
        return{
          seasonal_name:'Item',
        }
      },
      mounted(){
        axios
          .post("https://csce315-project3-backend.herokuapp.com/cashier/buttons/getName")
          .then((response) => {
            console.log(response)
            this.seasonal_name = response.data

          })
          .catch((error) => console.log(error));
      },
    }
  </script>
  
  <style scoped>
    .button {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(172, 28, 255);
      border-radius: 3px;
      color: rgb(172, 28, 255);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
  
    .button:hover {
      background: rgb(172, 28, 255);
      color: rgb(255, 255, 255);
    }
  </style>