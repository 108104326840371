<template>
    <button class="button">
      <div>
        <v-btn @click="onClick" pill>Add Item</v-btn>
      </div>
    </button>
  </template>
  
  <script>
    export default {
        props: {
    onClick: {
      type: Function,
      required: true,
    }
  }
    }
  </script>
  
  <style scoped>
    .button {
      display: inline-block;
      margin: 0.5em 0;
      padding: 1em 2em;
      background: #fff;
      border: 2px solid rgb(9, 206, 220);
      border-radius: 3px;
      color: rgb(9, 206, 220);
      font-family: "Quicksand", sans-serif;
      font-size: 1em;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      text-decoration-color: black;
      cursor: pointer;
      transition: 0.3s;
    }
  
    .button:hover {
      background: rgb(9, 206, 220);
      color: rgb(255, 255, 255);
    }
  </style>
  